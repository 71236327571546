import { PageTypes, RomanNumerals, ThemeContext } from "assets";
import { graphql } from "gatsby";
import {
  addExhibitionsParent,
  buildGalleryData,
  convertBreadcrumbNavigation,
  convertClusterNavigation,
  convertDirectionNavigation,
  convertHero,
} from "molecules";
import { Cluster, convertComponentList, PageLayout } from "organisms";
import PropTypes from "prop-types";
import React, { useRef } from "react";

const EXHIBIT_TYPE_THEMED = "themed";

const ChapterPage = ({ activeClusterId, data }) => {
  // Element reference used for sticky navigation
  const clusterStartRef = useRef();
  const chapterData = activeClusterId
    ? {
      ...data?.craftAPI?.entry?.parent,
    }
    : {
      ...data?.craftAPI?.entry,
    };

  const chapterTheme =
    chapterData.theme ||
    chapterData.parent?.theme ||
    chapterData.parent?.parent?.theme;
  const chapterFontType =
    chapterData.fontType ||
    chapterData.parent?.fontType ||
    chapterData.parent?.parent?.fontType;

  buildGalleryData(chapterData);
  const children = convertComponentList(chapterData?.componentList);

  // Determine the chapter number
  let chapterNum = chapterData.parent?.children?.findIndex(
    (child) => child.id === chapterData.id
  );

  // Place the hero at the top of the chapter
  children.unshift(
    convertHero({
      header:
        chapterData.parent?.parent?.presentationType !== EXHIBIT_TYPE_THEMED &&
        chapterNum > -1
          ? `Chapter ${String(chapterNum + 1).padStart(2, "0")}`
          : undefined,
      pageType: "chapter",
      ...chapterData,
    })
  );

  let breadcrumbData = {
    pageTitle: chapterData.title,
    parents: addExhibitionsParent(chapterData.parent),
  };

  let title = chapterData.title;
  const { coverImage, shortDescription } = chapterData;

  if (chapterData.children?.length) {
    // Include either the first cluster or the active cluster if provided
    const cluster = activeClusterId
      ? data?.craftAPI?.entry
      : chapterData.children[0];
    title = cluster.title;

    if (activeClusterId)
      breadcrumbData = {
        ...breadcrumbData,
        beginRef: clusterStartRef,
        typeHandle: cluster.typeHandle,
        pageTitle: cluster.title,
      };

    const clusterChildren = convertComponentList(cluster?.componentList);

    // Determine the section number
    let sectionNum = chapterData.children?.findIndex(
      (child) => child.id === cluster.id
    );

    // Place the hero at the top of the cluster
    clusterChildren.unshift(
      convertHero({
        subtitle:
          sectionNum > -1 &&
          chapterData.parent?.parent?.presentationType !== EXHIBIT_TYPE_THEMED
            ? `Section ${RomanNumerals[sectionNum]}`
            : "Section",
        ...cluster,
      })
    );
    clusterChildren.unshift(
      convertClusterNavigation({
        activePageId: cluster.id,
        pages: chapterData.children,
      })
    );
    clusterChildren.push(convertDirectionNavigation(cluster));

    children.push(
      <Cluster key={cluster.id} ref={clusterStartRef}>
        {clusterChildren}
      </Cluster>
    );
  } else {
    // No child clusters - include directional navigation
    children.push(convertDirectionNavigation(chapterData));
  }

  children.unshift(convertBreadcrumbNavigation(breadcrumbData));

  return (
    <ThemeContext.Provider
      value={{
        theme: chapterTheme,
        fontType: chapterFontType,
        pillarTitleColor: chapterData.parent.pillarTitleColor,
      }}
    >
      <PageLayout
        coverImage={coverImage}
        pageType={PageTypes.EXHIBITION}
        presentationType={chapterData.parent?.parent?.presentationType}
        shortDescription={shortDescription}
        theme={chapterTheme || undefined}
        title={title}
      >
        {children}
      </PageLayout>
    </ThemeContext.Provider>
  );
};

export const chapterQuery = graphql`
  query ($uri: [String], $section: [String] = "exhibit") {
    craftAPI {
      entry(uri: $uri) {
        ...BreadcrumbNavigationFragment
        ... on CraftAPI_exhibit_chapter_Entry {
          id
          uri
          coverImageCropStyle
          coverImagePositionOverride
          coverImage {
            ...ImageMetadataFragment
          }
          audioAsset {
            ...AudioAssetFragment
          }
          componentList {
            ...ComponentListFragment
          }
          heroType
          longDescription
          shortDescription
          fontType
          theme
          title
          backgroundImage {
            url
          }
          fontColor
          ...DirectionNavigationFragment
          # Pillar
          parent {
            ... on CraftAPI_exhibit_pillar_Entry {
              id
              title
              uri
              pillarTitleColor
              theme
              fontType
              # Exhibit
              parent {
                ... on CraftAPI_exhibit_exhibit_Entry {
                  presentationType
                  theme
                  fontType
                  children {
                    id
                  }
                }
              }
              children {
                id
              }
            }
          }
          # Cluster
          children {
            ... on CraftAPI_exhibit_cluster_Entry {
              id
              title
              uri
              audioAsset {
                ...AudioAssetFragment
              }
              coverImageCropStyle
              coverImagePositionOverride
              coverImage {
                ...ImageMetadataFragment
              }
              componentList {
                ...ComponentListFragment
              }
              heroType
              longDescription
              shortDescription
              ...DirectionNavigationFragment
              ...ClusterNavigationFragment
            }
          }
        }
      }
    }
  }
`;

ChapterPage.propTypes = {
  activeClusterId: PropTypes.string,
  data: PropTypes.shape({
    craftAPI: PropTypes.shape({
      entry: PropTypes.shape({
        parent: PropTypes.shape({}),
      }),
    }),
  }),
};

export default ChapterPage;
